<template lang="pug">
CornerRadius(
  :label="isThemeKit ? $t('borderThemeKitRounding') : $t('borderRadius')"
  :needsToggle="isThemeKit"
  :themeCorners.sync="themeCorners"
  :cornerValues="cornerValues"
  :isChained="isChained"
  :isFallback="isFallback"
  :showFallbackTooltip="mobilePreview && isEmptyMobileValues"
  :editMobile="editMobile"
  :deviceSelector="deviceSelector"
  :placeholders="desktopValues"
  @input="onInput"
  @setChained="setChained"
  @focused="isFocused = $event"
)
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import { GROUPS } from '@om/template-properties/src/types/radiusTypes';
  import CornerRadius from '../CornerRadius/CornerRadius.vue';

  export default {
    components: {
      CornerRadius,
    },
    mixins: [itemMixin],
    props: {
      value: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: true,
      },
      deviceSelector: {
        type: Boolean,
        default: true,
      },
      label: {
        type: String,
      },
      radiusProperty: {
        type: String,
        default: '$device.border.radius',
      },
      chainedProperty: {
        type: String,
        default: '$device.border.radiusChained',
      },
      customThemeRoundingProperty: {
        type: String,
        default: '$device.border.customThemeRounding',
      },
    },
    data: () => ({
      canEditMobile: true,
      isFocused: false,
    }),
    computed: {
      ...mapState(['selectedElement', 'mobilePreview', 'themeKit']),
      ...mapGetters(['isThemeKit']),
      isFallback() {
        if (!this.mobilePreview) return false;

        return !this.isFocused && this.isEmptyMobileValues;
      },
      isEmptyMobileValues() {
        const mobileValues = this.getValueOf(this.radiusProperty.replace('$device', 'mobile'));
        return mobileValues.every((value) => value === null);
      },
      desktopValues() {
        const desktopUseTheme = this.getValueOf(
          this.customThemeRoundingProperty.replace('$device', 'desktop'),
        );
        if (desktopUseTheme) {
          return new Array(4).fill(GROUPS?.[this.themeKit.rounding] ?? 0);
        }

        return this.getValueOf(this.radiusProperty.replace('$device', 'desktop'));
      },
      cornerValues() {
        return this.getValueOf(this.radiusProperty);
      },
      isChained: {
        get() {
          return this.getValueOf(this.chainedProperty);
        },
        set(v) {
          this.setValueOf(this.chainedProperty, v);
        },
      },
      themeCorners: {
        get() {
          return this.getValueOf(this.customThemeRoundingProperty);
        },
        set(value) {
          this.setValueOf(this.customThemeRoundingProperty, value);

          if (!value) {
            this.updateElementStyle(this.selectedElement.uid);
            return;
          }

          this.$store.commit('updateCustomThemeRadius', { value: this.themeKit.rounding });
        },
      },
    },
    methods: {
      setChained(value) {
        this.isChained = value;
        if (value) {
          const itemValue = this.getValueOf(this.radiusProperty);

          let newItemValue = JSON.parse(JSON.stringify(itemValue));
          newItemValue = itemValue.map(() => itemValue[0]);
          this.setValueOf(this.radiusProperty, newItemValue);
        }
      },
      onInput({ index, value }) {
        const itemValue = this.getValueOf(this.radiusProperty);

        let newItemValue = JSON.parse(JSON.stringify(itemValue));
        newItemValue[index] = value;

        if (this.isChained) {
          newItemValue = (newItemValue || []).map(() => value);
        }

        newItemValue = newItemValue.map((value) => Number(value));

        this.setValueOf(this.radiusProperty, newItemValue);

        this.updateElementStyle(this.selectedElement.uid);
      },
    },
  };
</script>
