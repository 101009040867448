<template lang="pug">
div
  Heading.mt-1(label) {{ $t('desktopDisplay') }}
    template(#prependEnding)
      OmSwitch#hideElementOnDesktop(
        :value="hideOnDesktop"
        @switch="$emit('update:hideOnDesktop', $event)"
      )
  Heading.mt-4(label) {{ $t('mobileDisplay') }}
    template(#prependEnding)
      OmSwitch#hideElementOnMobile(
        :value="hideOnMobile"
        @switch="$emit('update:hideOnMobile', $event)"
      )
</template>

<script>
  import Heading from '@/components/Editor/Heading.vue';

  export default {
    components: { Heading },
    props: {
      hideOnDesktop: {
        type: Boolean,
        required: true,
      },
      hideOnMobile: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>
