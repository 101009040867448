<template lang="pug">
.ds-corner-radius.design-system
  heading(label :open="!themeCorners || !needsToggle")
    template(#titleTrailing)
      .d-flex.align-items-center
        .label {{ label }}
        DeviceSelector(v-if="deviceSelector && editMobile" :hasViewText="false")
        .tooltip-icon.d-flex.align-items-center.ml-2
          OmTooltip.mr-3(v-if="showFallbackTooltip" transition="fade" big :arrow="false")
            span {{ $t('fallbackToDesktop') }}
    template(v-if="needsToggle" #prependEnding)
      OmSwitch#theme-corners(:value="themeCorners" @switch="$emit('update:themeCorners', $event)")
    template(#expandableContent)
      .ds-corner-radius-inputs(:class="{ fallback: isFallback }")
        .ds-corner-radius-left
          .ds-corner-radius-input-wrapper
            .ds-corner-radius-input
              input(
                type="number"
                :placeholder="getPlaceholder(0)"
                :value="getCornerValue(0)"
                :min="0"
                step="1"
                @focus="activeCorner = 'tl'"
                @blur="onBlur"
                @input="onInput($event, 0)"
              )
          .ds-corner-radius-input-wrapper
            .ds-corner-radius-input
              input(
                type="number"
                :placeholder="getPlaceholder(3)"
                :value="getCornerValue(3)"
                :min="0"
                step="1"
                @focus="activeCorner = 'bl'"
                @blur="onBlur"
                @input="onInput($event, 3)"
              )
        .ds-corner-radius-center
          OmButton.p-1(
            ghost
            icon="link-alt"
            iconOnly
            :class="{ selected: isChained }"
            @click="setChained"
          )
          <svg :class="{ active: activeCorner === 'tl' }" class="indicator tl color-gray-ultra-dark" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 2H14.5C7.87258 2 2.5 7.37258 2.5 14V18" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <svg :class="{ active: activeCorner === 'tr' }" class="indicator tr color-gray-ultra-dark" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 18L18.5 14C18.5 7.37258 13.1274 2 6.5 2L2.5 2" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <svg :class="{ active: activeCorner === 'bl' }" class="indicator bl color-gray-ultra-dark" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 2L2.5 6C2.5 12.6274 7.87258 18 14.5 18L18.5 18" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <svg :class="{ active: activeCorner === 'br' }" class="indicator br color-gray-ultra-dark" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 18L6.5 18C13.1274 18 18.5 12.6274 18.5 6L18.5 2" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
          </svg>
        .ds-corner-radius-right
          .ds-corner-radius-input-wrapper
            .ds-corner-radius-input
              input(
                type="number"
                :placeholder="getPlaceholder(1)"
                :value="getCornerValue(1)"
                :min="0"
                step="1"
                @focus="activeCorner = 'tr'"
                @blur="onBlur"
                @input="onInput($event, 1)"
              )
          .ds-corner-radius-input-wrapper
            .ds-corner-radius-input
              input(
                type="number"
                :placeholder="getPlaceholder(2)"
                :value="getCornerValue(2)"
                :min="0"
                step="1"
                @focus="activeCorner = 'br'"
                @blur="onBlur"
                @input="onInput($event, 2)"
              )
</template>

<script>
  import { UilQuestionCircle } from '@iconscout/vue-unicons';
  import OmButton from '@/components/Elements/Button';
  import OmSwitch from '@/components/Elements/Switch/Switch.vue';
  import OmTooltip from '@/components/Elements/Tooltip/Tooltip.vue';
  import Heading from '../Heading.vue';

  export default {
    name: 'CornerRadius',
    components: {
      Heading,
      OmButton,
      OmSwitch,
      UilQuestionCircle,
      OmTooltip,
      DeviceSelector: () => import('@/editor/components/sidebar/components/DeviceSelector.vue'),
    },
    props: {
      needsToggle: { type: Boolean, default: true },
      label: { type: String },
      themeCorners: { type: Boolean, default: false },
      editMobile: { type: Boolean, default: true },
      deviceSelector: { type: Boolean, default: true },
      cornerValues: { type: Array, default: () => [] },
      placeholders: { type: Array, default: () => [] },
      isChained: { type: Boolean, default: false },
      isFallback: { type: Boolean, default: false },
      showFallbackTooltip: { type: Boolean, default: false },
    },
    data: () => ({ activeCorner: null }),
    watch: {
      activeCorner(active) {
        this.$emit('focused', active);
      },
    },
    methods: {
      onBlur() {
        this.activeCorner = null;
      },
      onInput(event, index) {
        const value = event.target.value;
        this.$emit('input', { index, value });
        if (this.isFallback) {
          this.$emit('update:isFallback', false);
        }
      },
      setChained() {
        this.$emit('setChained', !this.isChained);
      },
      getCornerValue(index) {
        return this.cornerValues[index];
      },
      getPlaceholder(index) {
        return this.placeholders?.[index] ?? '';
      },
    },
  };
</script>

<style lang="sass">
  @import '../../../sass/variables/_colors.sass'

  .ds-corner-radius
    &-left,
    &-center,
    &-right
      display: flex
      flex-direction: column
      gap: .75rem
    &-center
      align-items: center
      justify-content: center
      align-self: center
      height: 3.5rem
      position: relative
      .btn.selected
        background: transparent
      .indicator
        position: absolute
        transition: color 200ms ease-in-out
        &.active
          color: $om-orange
        &.tl
          top: 0
          left: 0
        &.tr
          top: 0
          right: 0
        &.bl
          bottom: 0
          left: 0
        &.br
          bottom: 0
          right: 0
    &-right
      justify-content: end
    &-inputs
      margin-top: .75rem
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      gap: .5rem
      width: 100%
      &.fallback
        opacity: .4
    &-input
      position: relative
      color: $om-gray-700
      &::after
        content: "px"
        display: flex
        width: 1.75rem
        font-size: .875rem
        align-items: center
        justify-content: center
        height: 100%
        position: absolute
        top: 0
        right: 0
        pointer-events: none
      input
        height: 2rem
        width: 5rem
        padding: .25rem .5rem
        padding-right: 1.5rem
        padding-left: 1.25rem
        font-size: .875rem
        text-align: right
        position: relative
        color: $om-gray-700
        border-radius: .25rem
        border: 1px solid $om-gray-400
        background: $om-gray-50
        &::-webkit-inner-spin-button
          position: absolute
          left: .25rem
          display: block
          width: 1rem
          height: 1rem
          cursor: pointer
        &:hover::-webkit-inner-spin-button
          opacity: 1
        &:focus::-webkit-inner-spin-button
          opacity: 1
      &-wrapper
        display: flex
        gap: .5rem
        flex: 1 1 auto
</style>
